<template>
	<div>
    <van-field
      v-model="name"
      type="text"
      label="姓名"
      :label-width="100"
      disabled
    />
    <van-field
      v-model="mobile"
      type="text"
      label="手机号"
      :label-width="100"
      disabled
    />
		<van-field
			:value="currency"
			is-link
			readonly
			label="币种"
			placeholder="请选择币种"
			@click="show = true"
		/>
		<van-popup v-model="show" round position="bottom">
			<van-picker
				:columns="list"
				@cancel="show = false"
				@confirm="onConfirm"
				show-toolbar
			/>
		</van-popup>
    <van-field
      v-model="card_name"
      type="text"
      label="卡片"
      :label-width="100"
      disabled
    />
    <van-field
      v-model="address"
      type="textarea"
      label="地址"
      :label-width="100"
    />
    
    <div class="common_block">
      <van-button type="warning" block @click="postPapers">{{translate('submit_amount_options')}}</van-button>
    </div>
	</div>
</template>

<script>
	export default {
		name: 'personal_apply',
		data() {
			return {
				name:'',
        mobile:'',
        currency:'',
        card_name:'',
        address:'',
				show:false,
				list:['HKD','USD']
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			}
			this.getInit()
		},
		methods: {
			onConfirm(value){
				this.currency = value
				this.show = false
			},
      getInit(){
				this.$axios({
					method: 'post',
					url: 'speedpay_card/applyInit',
					data: {
						token:this.$route.query.token
					}
				}).then ((res) => {
					if (res.success) {
            this.name = res.data.name
            this.mobile = res.data.mobile
            this.currency = res.data.currency
            this.card_name = res.data.card_name
            this.address = res.data.address
					}else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
			},
			postPapers(){
				this.$axios({
					method: 'post',
					url: 'speedpay_card/apply',
					data: {
						token:this.$route.query.token,
						name:this.name,
						currency:this.currency,
						card_name:this.card_name,
						address:this.address
					}
				}).then ((res) => {
					if (res.success) {
						this.$dialog.alert({
							message: '开卡成功'
						})
					}else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
			}
		},
	}
</script>
<style scoped>
  .fixed{
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 999;
		background: #fff;
		padding: 10px;
		box-sizing: border-box;
		box-shadow: 0px 0px 10px #ccc;
		display: flex;
		justify-content: space-around;
	}
</style>
